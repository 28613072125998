import { defineStore } from 'pinia'

export const useCounterStore = defineStore('userInfo', {
    state: () => {
        return { userInfo: {} }
    },
    getters: {
        getUserInfo: (state) => state.userInfo
    },
    persist: {
        enabled: true,
        strategies: [{
            paths: ['userInfo']
        }]
    },
    actions: {
        setUserInfo(userInfo) {
            this.userInfo = userInfo
        },
    },
})
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import MenuChild from "./MenuChild.vue";
export default {
  __name: 'SubMenu',
  props: {
    item: {
      type: Object,

      default() {
        return {};
      }

    }
  },

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_location = _resolveComponent("location");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_sub_menu = _resolveComponent("el-sub-menu");

      return _openBlock(), _createBlock(_component_el_sub_menu, {
        index: __props.item.path
      }, {
        title: _withCtx(() => [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_location)]),
          _: 1
        }), _createElementVNode("span", null, _toDisplayString(__props.item.meta.title), 1)]),
        default: _withCtx(() => [_createVNode(MenuChild, {
          menuList: __props.item.children
        }, null, 8, ["menuList"])]),
        _: 1
      }, 8, ["index"]);
    };
  }

};
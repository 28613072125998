import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import menuChild from "./MenuChild.vue";
import router from "@/router/menuRouter.js";
import { useRoute } from "vue-router";
import { watch, ref, nextTick } from "vue";
export default {
  __name: 'index',
  props: {
    isCollapse: {
      default: false,
      type: Boolean
    }
  },

  setup(__props) {
    const menuRouter = JSON.parse(JSON.stringify(router));
    const myUseRoute = useRoute();
    const defaultActive = ref("");
    watch(() => myUseRoute, val => {
      nextTick(() => {
        if (val.meta.activePath) {
          defaultActive.value = val.meta.activePath;
        } else {
          defaultActive.value = val.path;
        }
      });
    }, {
      immediate: true,
      deep: true
    });

    function removeHiddenRouter(routers) {
      for (let i = 0; i < routers.length; i++) {
        if (routers[i].hidden) {
          routers.splice(i, 1);
          i--;
        } else {
          if (routers[i].children ? true : false) {
            removeHiddenRouter(routers[i].children);
          }
        }
      }
    }

    removeHiddenRouter(menuRouter);
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");

      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": defaultActive.value,
        router: true,
        collapse: __props.isCollapse
      }, {
        default: _withCtx(() => [_createVNode(menuChild, {
          menuList: _unref(menuRouter)
        }, null, 8, ["menuList"])]),
        _: 1
      }, 8, ["default-active", "collapse"]);
    };
  }

};
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
import SubMenu from "./SubMenu";
export default {
  __name: 'MenuChild',
  props: {
    menuList: {
      type: Array,

      default() {
        return [];
      }

    }
  },

  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_menu_item = _resolveComponent("el-menu-item");

      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.menuList, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, {
          key: index
        }, [item.children && item.children.length > 0 ? (_openBlock(), _createBlock(_unref(SubMenu), {
          key: 0,
          item: item
        }, null, 8, ["item"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
          key: 1,
          index: item.path
        }, {
          default: _withCtx(() => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_resolveDynamicComponent(item.meta.icon)))]),
            _: 2
          }, 1024), _createElementVNode("span", null, _toDisplayString(item.meta.title), 1)]),
          _: 2
        }, 1032, ["index"]))], 64);
      }), 128);
    };
  }

};